html {
  position: relative;
  min-height: 100%;
}
html.login,
html.rasen-bg {
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}
body {
  padding-top: 110px;
  margin-bottom: 60px;
  font-family: Open Sans, sans-serif;
  background-color: transparent;
}
.footer {
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  background-color: #25292B;
}
.footer .container .text-muted {
  color: #939393;
}
.footer .container a {
  color: #939393;
}
.flag {
  width: 16px;
  height: 11px;
  background: url(/img/flags.png) no-repeat;
  display: inline-block;
  margin-right: 0.3em;
  line-height: 11px;
  vertical-align: text-top;
  margin-top: 1px;
}
.flag.flag-de {
  background-position: -16px 0;
}
.flag.flag-fr {
  background-position: -32px 0;
}
.flag.flag-gb {
  background-position: 0 -11px;
}
.flag.flag-lu {
  background-position: -16px -11px;
}
.flag.flag-be {
  background-position: 0 0px;
}
.customer-name > a,
.customer-name > a:visited,
.customer-name > a:hover {
  color: black;
}
.list.decrease-left-padding {
  padding-left: 10px;
}
.list .list-header {
  border-bottom: 2px solid #dddddd;
  padding-bottom: 7px;
  font-weight: bold;
  font-size: 11px;
  color: grey;
}
.list .col-row-indicators {
  padding: 0 0 0 8px;
  width: 28px;
}
.list .list-row {
  border: 1px solid #999;
  border-radius: 4px;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}
.list .list-row.state-0 {
  background-color: #faa;
}
.list .list-row.state-0.imported {
  background-color: #fee;
}
.list .list-row.state-1 {
  background-color: #ffffff;
}
.list .list-row.state-2 {
  background-color: #FAFF8C;
}
.list .list-row.state-2.cutter_as2 {
  background-color: #FF6;
}
.list .list-row.state-2.cutter_tsr {
  background-color: #ffde66;
}
.list .list-row.state-2.containsItemsFromStock {
  background-color: #d1d4aa;
}
.list .list-row.state-3 {
  background-color: #83D5FD;
}
.list .list-row.state-4 {
  background-color: #5DF863;
}
.list .list-row.urgent {
  background-color: #f2dede !important;
}
.list .list-row.paypal {
  border: 2px solid #1862c4;
}
.list .list-row .btn {
  margin: 4px 0;
}
.list .list-row .truck {
  margin-top: -1px;
}
.list .list-row .emphasize {
  font-size: 110%;
  font-weight: bold;
}
.list .list-row .emphasize.more {
  font-size: 120%;
}
.list .list-row .main-info {
  margin-bottom: 7px;
}
.list .list-row .key {
  color: grey;
  font-size: 12px;
  margin-right: 5px;
}
.list .list-row .value {
  font-size: 12px;
  font-weight: bold;
}
.list .list-row .additional-info {
  margin-right: 13px;
}
.list .list-row .offset-top {
  margin-top: 13px;
}
.list .list-row .delivery-icon {
  margin-top: 10px;
}
.list .list-row .delivery-truck-name {
  margin-top: 14px;
}
.list .list-row .action-buttons {
  text-align: left;
}
@media (min-width: 1199px) {
  .list .list-row .action-buttons {
    margin-top: 5px;
  }
}
.row#order-totals {
  border: 0px solid black;
}
#edit-form-container .list-group .list-group-item.containsItemsFromStock {
  background-color: #ffe68c;
}
#edit-form-container .list-group .list-group-item.willBeTakenFromStock {
  background-color: #ffd68c;
}
.userlist .userrow {
  display: table-row;
}
.userlist .usercell {
  display: table-cell;
  padding: 2px 20px 0 0;
}
.userlist .disabled-user > .usercell {
  color: #888;
}
.userlist .usercell.actions {
  width: 100%;
  text-align: right;
  padding-right: 20px;
}
.selectize-control .selectize-icon {
  margin-right: 7px !important;
}
.selectize-control .add-option {
  font-weight: bold;
  margin-right: 5px;
}
#order-customer-container .selectize-input {
  border-right: none;
  border-radius: 4px 0px 0px 4px;
  margin-bottom: -5px;
}
#order-customer-container .selectize-dropdown {
  margin-top: 5px;
}
#order-customer-container .selectize-dropdown-content {
  max-height: 480px;
}
#order-customer-container .selectize-dropdown-content .selectize-item-detail {
  margin-left: 2em;
  font-size: 0.8em;
  color: #777;
  border-bottom: 1px solid #CCC;
}
.navbar-btn-stacked.create-new {
  height: 70px;
  font-size: 3em;
}
.navbar-btn-stacked.btn-group {
  width: 190px;
}
.navbar-btn-stacked.btn-group label {
  width: 95px;
}
.btn-tooltip-wrapper {
  display: inline-block;
  margin: 4px 0;
}
.btn-tooltip-wrapper > .btn {
  margin: 0;
}
.navbar-customer {
  min-height: 100px;
  background-color: #fff;
  border-color: #89C458;
  border-bottom: 16px solid #89C458;
}
.navbar-customer .navbar-brand {
  padding: 10px 15px;
}
.navbar-customer .navbar-login {
  color: #888;
  text-transform: uppercase;
}
.navbar-customer .navbar-login > li > a:hover,
.navbar-customer .navbar-login > li > a:focus,
.navbar-customer .navbar-login > .active > a,
.navbar-customer .navbar-login > .active > a:hover,
.navbar-customer .navbar-login > .active > a:focus {
  color: #89C458;
  background-color: #fff;
}
@media (max-width: 460px) {
  .navbar-brand img {
    height: 70px;
  }
}
@media (min-width: 768px) {
  .navbar-customer .navbar-nav > li > a {
    padding-top: 30px;
    padding-bottom: 30px;
    line-height: 30px;
  }
}
.form-signin {
  color: white;
  max-width: 380px;
  padding: 15px;
  margin: 5em auto;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.3) url('/img/pattern-2.png');
}
.form-signin h2 {
  font-size: 26px;
}
.form-signin button.btn-success {
  background-color: #25292B;
  border-color: #25292B;
  border-radius: 3px;
  font-weight: bold;
}
.form-signin button.btn-success:hover {
  color: #222;
  background-color: #d1d1d1;
}
.form-signin a,
.form-signin a:hover {
  color: #fff;
}
.form-signin .form-signin-heading,
.form-signin .checkbox {
  margin-bottom: 10px;
}
.form-signin .checkbox {
  font-weight: normal;
}
.form-signin .form-control {
  position: relative;
  height: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="text"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
@media (max-width: 1199px) {
  button.hidden-on-small,
  a.hidden-on-small {
    display: none;
  }
}
@media (max-width: 1199px) {
  button.wider-on-small,
  a.wider-on-small {
    padding: 7px 14px !important;
  }
}
@media (max-width: 1199px) {
  .list .list-row div.emphasize.more {
    margin: auto;
  }
}
@media (max-width: 1199px) {
  .list .list-row div.emphasize.big-text-on-tablets {
    margin: 10px 0;
    line-height: 12px;
    font-size: 120%;
  }
  .list .list-row div.emphasize.big-text-on-tablets.product-names {
    line-height: 1;
  }
}
@media (max-width: 1199px) {
  .list .list-row div.emphasize.big-text-on-tablets.product-names {
    margin-top: 11px;
    line-height: 12px;
    font-size: 10px;
  }
}
#order-totals {
  margin-top: 15px;
}
#totals-title {
  text-transform: uppercase;
}
@media (min-width: 1200px) {
  #palettes-total {
    padding-left: 15px;
  }
  #sq-mt-total {
    padding-left: 14px;
  }
}
@media (max-width: 1199px) {
  #palettes-total {
    padding-left: 25px;
  }
}
.extra-large {
  padding: 20px 32px !important;
}
@media (max-width: 1600px) {
  .orders-list-controls .btn-lg:not(.create-new),
  .orders-list-controls .btn-group-lg > .btn {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }
  .orders-list-controls .input-lg {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }
}
#below-login-form {
  margin-top: 10px;
}
.registration-link {
  background-color: #222;
  color: white;
  max-width: 380px;
  padding: 15px;
  margin: 5em auto;
  border-radius: 6px;
}
.registration-link p {
  font-size: 12px;
}
.registration-link a[role="button"] {
  background-color: white;
  color: #222;
  font-weight: bold;
}
#overview .tab-content {
  margin-top: 13px;
  max-height: 960px;
  overflow-y: auto;
}
#overview .date-chunck .chunck-header h4 {
  width: 80px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3px;
  margin-bottom: 3px;
  font-size: 16px;
}
#overview .date-chunck .tour-container .header > h3 {
  font-size: 15px;
  padding: 5px 15px 5px 15px;
  border-bottom: 1px solid #ddd;
}
#overview .date-chunck .tour-container .orders .row {
  margin-left: 0px;
  margin-right: 0px;
}
#overview .date-chunck .tour-container .orders ol {
  padding-left: 0px;
}
#line-item-container .panel-heading {
  position: relative;
}
#line-item-container .panel-heading .line-item-heading-icons {
  position: absolute;
  top: 3px;
  right: 4px;
}
#line-item-container .product-info,
#line-item-container .product-icons {
  margin-top: 5px;
  padding-right: 2px;
}
#order-form .price span.total-price {
  border-bottom: 1px solid black;
  font-weight: bold;
}
#order-form .price span.smaller-text {
  font-size: 12px;
}
#order-form .price span.vat-indicator.vat-free {
  text-decoration: line-through;
}
.extra-bottom-space {
  margin-bottom: 4em;
}
.address-suggestion-intro {
  margin-bottom: 1em;
}
@media (max-width: 1199px) {
  div.filter-bar {
    display: none;
  }
}
@media (max-width: 1199px) {
  div.orders-list {
    width: 100%;
  }
}
.pallets-count {
  cursor: pointer;
}
#stock-mode-toggle .glyphicon,
#show-stock-toggle .glyphicon {
  font-size: 22px;
  margin-left: 8px;
}
#stock-mode-toggle .glyphicon-check,
#show-stock-toggle .glyphicon-check {
  display: none;
}
#stock-mode-toggle .btn-icon,
#show-stock-toggle .btn-icon {
  width: 32px;
  height: auto;
}
#stock-mode-toggle.active,
#show-stock-toggle.active {
  background-color: #ec971f;
  border-color: #d58512;
}
#stock-mode-toggle.active .glyphicon-unchecked,
#show-stock-toggle.active .glyphicon-unchecked {
  display: none;
}
#stock-mode-toggle.active .glyphicon-check,
#show-stock-toggle.active .glyphicon-check {
  display: inline;
}
@media (max-width: 1600px) {
  #stock-mode-toggle .glyphicon,
  #show-stock-toggle .glyphicon {
    font-size: 14px;
    margin-left: 8px;
  }
  #stock-mode-toggle .btn-icon,
  #show-stock-toggle .btn-icon {
    width: 16px;
    height: auto;
  }
}
#stock-indicator .volume {
  font-size: 16px;
  font-weight: bold;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
#cutter-toggle span.glyphicon {
  display: inline-block;
  margin-right: 12px;
  font-size: 24px;
}
.container-overlay {
  margin-bottom: 64px;
}
.container-overlay .content {
  background-color: #FFF;
  padding: 2em;
}
.container-overlay .content h1 {
  margin-top: 0;
  margin-bottom: 1.33em;
}
.container-overlay .content h3 {
  margin-top: 0.83em;
  margin-bottom: 0.66em;
}
.container-overlay .content p {
  margin-bottom: 1em;
  text-align: justify;
}
.is-paid {
  color: #66ee66;
  font-weight: bold;
}
.is-reminder {
  color: #FF0000;
  font-weight: bold;
}
#export-document-container {
  margin-top: -55px;
}
.spacer {
  height: 30px;
}
input[type="radio"].radio-btn {
  display: none;
}
.exports-page h3.panel-title.inline {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.6em;
  font-size: 1.2em;
}
.exports-page .form-inline + .form-inline,
.exports-page .form-inline + .from-group {
  margin-top: 1em;
}
.exports-page .form-inline > .form-group + .form-group {
  margin-left: 1em;
}
#export_selected_form td.compact {
  padding: 2px 0;
}
#export_selected_form .table-inner {
  margin: 0;
  border: 0;
  background-color: transparent;
}
#export_selected_form .table-inner td {
  border-top: 0;
  border-bottom: 0;
  text-align: center;
}
#export_selected_form .table-inner td:nth-child(1) {
  width: 100px;
}
#export_selected_form .table-inner td:nth-child(2) {
  width: 100px;
}
#export_selected_form .table-inner td:nth-child(3) {
  width: 33px;
}
#export_selected_form .table-inner td:nth-child(4) {
  width: 100px;
}
.order-confirmation th.order-title {
  padding-right: 35px;
  vertical-align: top;
}
.container .text-muted {
  margin: 10px 0 20px;
}
.container h1.cover {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
.container-login h1 {
  font-size: 42px;
  font-weight: bold;
}
.container .text-tiny {
  font-size: 0.75em;
}
label.info-label {
  font-size: 9px;
}
div.button-wrapper {
  display: inline-block;
}
input.left-side {
  border-radius: 4px 0px 0px 4px;
}
input.right-side {
  border-radius: 0px 4px 4px 0px;
  border-left: none;
}
label.control-label.optional {
  padding-top: 0px;
}
label.control-label.optional .label-text {
  display: block;
}
label.control-label.optional .label-disclaimer {
  display: block;
  font-size: 10px;
  font-weight: initial;
}
.no-left-padding {
  padding-left: 0px;
}
.no-right-padding {
  padding-right: 0px;
}
#right-side .nav li a {
  padding: 6px 12px;
}
.centered {
  margin-left: auto;
  margin-right: auto;
}
.margin-bottom {
  margin-bottom: 7px;
}
textarea.no-resize {
  resize: none;
}
.text-xs {
  font-size: 66%;
}
.hide {
  display: none;
}
.tt-cursor {
  background-color: #cdf0fd;
}
.actual-zip {
  font-weight: bold;
}
.ttf-loading {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.8) url('/img/ajax-loader.gif') 50% 50% no-repeat;
}
.alert.condensed {
  padding: 6px 12px !important;
  margin-bottom: 0px !important;
}
.tooltip.error .tooltip-inner {
  background-color: #953b39;
}
.tooltip.error .tooltip-arrow {
  border-top-color: #953b39;
}
.row-left-margin {
  margin-left: 0px !important;
}
.row-top-margin {
  margin-top: 12px;
}
.top-margin-lg {
  margin-top: 48px;
}
.row.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.row.no-gutters > [class^="col-"] {
  padding-right: 0;
  padding-left: 0;
}
span.twitter-typeahead {
  width: 100%;
}
.today-date {
  background-color: bisque;
  font-weight: bold;
}
.navbar-second {
  border-top: 1px solid #e7e7e7;
  top: 50px;
}
.nav button.btn,
.nav input {
  margin-top: 8px;
  margin-right: 10px;
}
.nav.navbar-right button.btn {
  margin-left: 10px;
}
.edit-panel {
  padding-right: 25px !important;
  position: fixed !important;
  top: 0;
  z-index: 99;
  right: 0;
  height: 100%;
  border-left: 1px solid #e7e7e7;
  padding-top: 110px;
  padding-bottom: 25px;
  background-color: #f8f8f8;
  overflow-y: scroll;
}
.edit-panel-underlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 98;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}
.alphabet-left-offset {
  margin-left: 60px;
}
.nav-tabs > li > a {
  padding: 5px;
}
.tab-pane {
  padding-top: 7px;
}
hr {
  color: #dddddd;
  background-color: #dddddd;
  height: 2px;
  margin: -3px 0 11px 0;
}
header > hgroup > h1 {
  float: left;
}
header > hgroup > h2 {
  margin-left: 40px;
  float: left;
}
h3 {
  margin-top: 10px;
}
@media (max-width: 768px) {
  ul.state-filter > li {
    line-height: 1em;
  }
}
.height100 {
  height: 60%;
}
#offset-container {
  padding: 0 15px 2em;
}
.vertical-middle tbody > tr > td {
  padding: 20px 0;
}
.right {
  float: right;
}
.seconds {
  font-weight: bold;
}
input.form-control.error,
select.form-control.error,
textarea.form-control.error,
.error > .selectize-control > .selectize-input,
.error > .input-group > .selectize-control > .selectize-input {
  border-color: #b94a48;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
input.form-control.error:focus,
select.form-control.error:focus,
textarea.form-control.error:focus,
.error > .selectize-control > .selectize-input:focus,
.error > .input-group > .selectize-control > .selectize-input:focus {
  border-color: #953b39;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #d59392;
}
input.form-control.success,
select.form-control.success,
textarea.form-control.success {
  border-color: #468847;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
input.form-control.success:focus,
select.form-control.success:focus,
textarea.form-control.success:focus {
  border-color: #356635;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7aba7b;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7aba7b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7aba7b;
}
input.form-control.info,
select.form-control.info,
textarea.form-control.info {
  border-color: #3a87ad;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
input.form-control.info:focus,
select.form-control.info:focus,
textarea.form-control.info:focus {
  border-color: #2d6987;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7ab5d3;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7ab5d3;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #7ab5d3;
}
input.form-control.warning,
select.form-control.warning,
textarea.form-control.warning {
  border-color: #c09853;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
input.form-control.warning:focus,
select.form-control.warning:focus,
textarea.form-control.warning:focus {
  border-color: #a47e3c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e;
  -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #dbc59e;
}
.form-horizontal-override .form-group {
  margin-right: 0px;
  margin-left: 0px;
}
.taxrate {
  color: grey;
}
.pricing-container .form-group {
  margin-bottom: 0px;
}
body.dragging,
body.dragging * {
  cursor: move !important;
}
#map-container {
  margin-right: 15px;
}
.panel-heading.with-buttons {
  position: relative;
  cursor: pointer;
  padding: 14px 20px;
}
.panel-heading.with-buttons .panel-buttons {
  position: absolute;
  right: 20px;
  top: 7px;
}
.order-backlog-container .panel-heading.with-buttons .panel-buttons {
  display: flex;
  align-items: center;
}
.order-backlog-container .panel-heading.with-buttons .panel-buttons label {
  min-width: 5rem;
  margin-right: 1rem;
}
.tour-list-container .panel.active.panel-default {
  border-color: #dfdfdf;
}
.tour-list-container .panel.active .panel-heading {
  background-color: #dfdfdf;
}
#truck-list-container .tour-dates li:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
#truck-list-container .tour-dates li.active:hover {
  background-color: #428bca;
}
#truck-list-container .tour-dates li.active a {
  color: #ffffff;
}
li.disabled {
  border: none;
}
li.disabled:hover {
  cursor: default;
  background-color: white;
}
.panel-body .header {
  font-size: 11px;
  font-weight: bolder;
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: 5px;
  margin-top: -6px;
}
.panel-body .panel-list {
  counter-reset: li;
  margin-left: 0;
  margin-bottom: 0;
  padding-left: 0;
}
.panel-body .panel-list li {
  box-sizing: content-box;
  margin-bottom: 7px;
  padding: 15px;
  list-style: none;
  border: 1px solid #dddddd;
  border-radius: 3px;
  cursor: pointer;
}
.panel-body .panel-list li.disabled {
  border: none;
}
.panel-body .panel-list li.disabled:hover {
  cursor: default;
  background-color: white;
}
.panel-body .panel-list li:hover {
  background-color: #f4fcff;
}
.panel-body .panel-list li:last-child {
  margin-bottom: 0;
}
.panel-body .panel-list li.dragged {
  position: absolute;
  opacity: 0.5;
  z-index: 2000;
  top: 0;
  padding: 15px 20px 15px 15px;
}
.panel-body .panel-list li.placeholder {
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
  height: 1px;
  background-color: #faa;
}
.panel-body .panel-list li.placeholder:not(:last-child) {
  margin-bottom: 5px;
}
.panel-body .panel-list li.placeholder:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  margin-top: -1px;
  left: -5px;
  top: -4px;
  border: 5px solid transparent;
  border-left-color: red;
  border-right: none;
}
input::-ms-clear {
  display: none;
}
div.form-group input[type=checkbox] {
  width: 30px;
}
.hidden {
  display: none;
}
label.checkbox-inline input[type=checkbox],
div.checkbox input[type=checkbox],
label.radio-inline input[type=radio] {
  position: relative;
  top: 2px;
}
div.col-xs-3 > label.radio-inline {
  width: 100%;
}
div.col-xs-3 > label.radio-inline > input[type=radio] {
  left: 0px;
  margin-left: 0.5em;
}
.scaled-button {
  -webkit-transform: scale(2, 2);
  -ms-transform: scale(2, 2);
  transform: scale(2, 2);
}
.alert-notification-navbar {
  color: #f33;
}
#pending-orders-count {
  position: relative;
  left: 2px;
}
.list-row.row.last-morning-order + .order-row.afternoon-order {
  margin-top: 3em;
}
.row.order-summary {
  margin: 10px 0 30px 0;
  font-weight: bold;
  text-transform: uppercase;
}
.row.order-summary small {
  font-weight: normal;
  font-size: 66%;
}
#morning-order-summary {
  color: #A55;
}
#afternoon-order-summary {
  color: #5A5;
}
#order-totals {
  margin-top: 20px;
  color: #555;
  font-size: 1.2em;
}
#customer-payment-method-container {
  margin-top: 5px;
  position: relative;
  left: 10px;
}
#customer-payment-method-container {
  font-size: 8pt;
}
#customer-payment-method-container > span#pallet_balance:before {
  content: '|';
  padding: 0 0.33em;
}
.note-alert {
  margin: 8px 10px 2px -10px;
  padding: 9px;
}
#pays-cash {
  color: #F0AD4E;
  font-weight: bold;
}
.gigantic-dollar-sign {
  position: relative;
  top: -1px;
  margin-left: 5px;
  font-weight: 900;
  color: red;
}
.progress-row {
  position: relative;
  z-index: 999;
  width: 100%;
  height: 8px;
  margin: 0 auto;
  padding-right: 0;
  padding-left: 0;
}
.progress-button {
  height: 100%;
  padding: 0 !important;
  margin: 0 !important;
  display: block;
  float: left;
  font-size: 0;
  font-weight: bold;
  border-radius: 0;
  box-sizing: border-box;
  border-top: 1px solid #5f5f5f;
}
.progress-button:disabled {
  cursor: default;
}
.pallet-done {
  background-color: #64c464;
  color: white;
}
.duetime-label {
  margin-top: 15px !important;
  font-size: 16px !important;
}
.navbar-btn-stacked.create-new {
  height: 70px;
}
.navbar-btn-stacked.btn-group {
  width: 190px;
}
.navbar-btn-stacked.btn-group label {
  width: 95px;
}
#orders-list-date-changer {
  position: relative;
  top: 3px;
}
.notification {
  margin-top: 9px;
}
#filter-bar {
  padding: 5px;
  margin-top: 5px;
  width: 190px;
}
.big-red-warning {
  color: #c11;
}
ol.sortable-orders {
  min-height: 4px;
}
.time-of-day-toggle-tours {
  margin-bottom: 21px;
}
#tours-interface-pills {
  margin-bottom: 16px;
}
.btn[disabled="disabled"] {
  filter: opacity(33%) blur(0.5px);
  cursor: initial;
}
.underlined {
  padding-bottom: 1em;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  margin-bottom: 1em;
}
.clickable {
  cursor: pointer;
}
.itunes-badge {
  display: inline-block;
  overflow: hidden;
  background: url('/img/appstore-lrg.svg') no-repeat;
  width: 135px;
  height: 40px;
  background-size: contain;
}
.cookie-consent {
  position: fixed;
  bottom: 0;
  z-index: 999;
  width: 100%;
  padding: 10px 10%;
  background: #000;
  /* #acd2b5;*/
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  color: #FFF;
  font-size: 1.4rem;
  line-height: 32px;
  text-align: center;
  overflow: hidden;
}
.cookie-consent > span {
  white-space: nowrap;
}
.cookie-consent .btn-group {
  white-space: nowrap;
}
@media only screen and (max-width: 544px) {
  .cookie-consent {
    text-align: left;
  }
  .cookie-consent > span {
    white-space: normal;
  }
  .cookie-consent .btn-group {
    display: block;
  }
}
.cookie-consent a:link,
.cookie-consent a:visited,
.cookie-consent a:hover,
.cookie-consent a:active {
  color: #FFF;
}
.cookie-consent a:hover {
  text-decoration: underline;
}
.cookie-consent .cc-btn {
  margin-left: 0.66rem;
  padding: 0.3rem 0.4rem;
  background-color: #2e8d25;
  border-radius: 1px;
  color: #FFF;
  font-size: 0.9em;
  text-transform: uppercase;
  cursor: pointer;
}
.edit-progress-button {
  display: none;
}
.actions {
  text-align: right;
  padding-right: 10px;
}
.delivery-truck-name {
  margin-top: 8px !important;
}
.create-new-container {
  padding-left: 0;
  padding-right: 0;
}
.date-control-bar {
  margin: 0;
  padding: 0;
}
#orders-list-date-changer {
  float: right;
}
.pallet-count-container {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}
.pallet-count-container .pallet-count-editor {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  z-index: 1000;
  text-align: center;
  width: 50%;
  height: 22rem;
  box-shadow: 0 0 25px 25px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.pallet-count-container .pallet-count-editor .minus,
.pallet-count-container .pallet-count-editor .plus,
.pallet-count-container .pallet-count-editor .pallet-number {
  position: relative;
  padding: 0;
  font-size: 15rem;
  height: 100%;
}
.pallet-count-container .pallet-count-editor .minus .btn,
.pallet-count-container .pallet-count-editor .plus .btn,
.pallet-count-container .pallet-count-editor .pallet-number .btn {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0;
  justify-content: center;
}
.pallet-count-container .pallet-count-editor .minus .btn span,
.pallet-count-container .pallet-count-editor .plus .btn span,
.pallet-count-container .pallet-count-editor .pallet-number .btn span {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.pallet-count-container .pallet-count-editor .minus .btn {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.pallet-count-container .pallet-count-editor .plus .btn {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.logo-web {
  max-width: 209px;
}
