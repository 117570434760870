#overview .tab-content {
  margin-top: 13px;
  max-height: 960px;
  overflow-y: auto;
}
#overview .date-chunck .chunck-header h4 {
  width: 80px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 3px;
  margin-bottom: 3px;
  font-size: 16px;
}
#overview .date-chunck .tour-container .header > h3 {
  font-size: 15px;
  padding: 5px 15px 5px 15px;
  border-bottom: 1px solid #ddd;
}
#overview .date-chunck .tour-container .orders .row {
  margin-left: 0px;
  margin-right: 0px;
}
#overview .date-chunck .tour-container .orders ol {
  padding-left: 0px;
}
