.customer-name > a,
.customer-name > a:visited,
.customer-name > a:hover {
  color: black;
}
.list.decrease-left-padding {
  padding-left: 10px;
}
.list .list-header {
  border-bottom: 2px solid #dddddd;
  padding-bottom: 7px;
  font-weight: bold;
  font-size: 11px;
  color: grey;
}
.list .col-row-indicators {
  padding: 0 0 0 8px;
  width: 28px;
}
.list .list-row {
  border: 1px solid #999;
  border-radius: 4px;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}
.list .list-row.state-0 {
  background-color: #faa;
}
.list .list-row.state-0.imported {
  background-color: #fee;
}
.list .list-row.state-1 {
  background-color: #ffffff;
}
.list .list-row.state-2 {
  background-color: #FAFF8C;
}
.list .list-row.state-2.cutter_as2 {
  background-color: #FF6;
}
.list .list-row.state-2.cutter_tsr {
  background-color: #ffde66;
}
.list .list-row.state-2.containsItemsFromStock {
  background-color: #d1d4aa;
}
.list .list-row.state-3 {
  background-color: #83D5FD;
}
.list .list-row.state-4 {
  background-color: #5DF863;
}
.list .list-row.urgent {
  background-color: #f2dede !important;
}
.list .list-row.paypal {
  border: 2px solid #1862c4;
}
.list .list-row .btn {
  margin: 4px 0;
}
.list .list-row .truck {
  margin-top: -1px;
}
.list .list-row .emphasize {
  font-size: 110%;
  font-weight: bold;
}
.list .list-row .emphasize.more {
  font-size: 120%;
}
.list .list-row .main-info {
  margin-bottom: 7px;
}
.list .list-row .key {
  color: grey;
  font-size: 12px;
  margin-right: 5px;
}
.list .list-row .value {
  font-size: 12px;
  font-weight: bold;
}
.list .list-row .additional-info {
  margin-right: 13px;
}
.list .list-row .offset-top {
  margin-top: 13px;
}
.list .list-row .delivery-icon {
  margin-top: 10px;
}
.list .list-row .delivery-truck-name {
  margin-top: 14px;
}
.list .list-row .action-buttons {
  text-align: left;
}
@media (min-width: 1200px) {
  .list .list-row .action-buttons {
    margin-top: 5px;
  }
}
.row#order-totals {
  border: 0px solid black;
}
#edit-form-container .list-group .list-group-item.containsItemsFromStock {
  background-color: #ffe68c;
}
#edit-form-container .list-group .list-group-item.willBeTakenFromStock {
  background-color: #ffd68c;
}
