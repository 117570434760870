
.stock-entries-list {
  font-size: 2rem;
  padding: 1em 1em 0 1em;
}
@media screen and (max-width: 767px) {
.stock-entries-list {
    font-size: 1.5rem;
}
}
@media screen and (max-width: 639px) {
.stock-entries-list {
    font-size: 1rem;
}
}
.stock-entries-list .glyphicon {
  top: 4px;
}
.stock-actions, .stock-rows {
  margin-bottom: 1em;
}
.stock-actions .btn, .stock-col .btn {
  font-size: 1em;
}
.cols {
  display: flex;
  flex-flow: row nowrap;
}
.cols > * {
  margin-left: .5em;
}
.cols > *:first-child {
  margin-left: 0;
}
.cols-spacer {
  flex-grow: 1;
}
.stock-rows {
  font-size: 1vw;
  display: flex;
  flex-flow: column nowrap;
  font-size: 1.25em;
}
.stock-row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: stretch;
  border: 1px solid black;
  border-top: none;
}
.stock-row-header {
  color: #555;
  font-size: .8em;
  font-weight: bold;
  text-transform: uppercase;
}
.stock-row:first-of-type {
  border-top: 1px solid black;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
.stock-row:last-of-type {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.stock-col {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-basis: 20%;
  padding: .5em;
}
.stock-col-name {
  flex-basis: 30%;
}
.stock-col-volume {
  flex-basis: 35%;
  background-color: rgba(91, 192, 222, 0.25);
  font-size: 1.25em;
}
.stock-col-capacity {
  flex-basis: 15%;
  background-color: rgba(51, 122, 183, .25);
}
.stock-col-unit {
  flex-basis: 10%;
}
.stock-col-actions {
  flex-basis: 10%;
}
.step-size {
  width: 50%;
  min-width: 10em;
  background-color: rgba(238, 162, 54, .25);
}
