.userlist .userrow {
  display: table-row;
}
.userlist .usercell {
  display: table-cell;
  padding: 2px 20px 0 0;
}
.userlist .disabled-user > .usercell {
  color: #888;
}
.userlist .usercell.actions {
  width: 100%;
  text-align: right;
  padding-right: 20px;
}
