.invoice-preview .overlay {
  position: fixed;
  z-index: 9999;
}
.invoice-preview .overlay .alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.invoice-preview .preview-foreground {
  padding: 0.5cm;
  min-height: 1200px;
  background-color: #fff;
}
.invoice-preview input[type=number] {
  width: 80px;
}
.invoice-preview .document-titles h1,
.invoice-preview .document-titles h2,
.invoice-preview .document-titles h3,
.invoice-preview .document-titles h4,
.invoice-preview .document-titles h5,
.invoice-preview .document-titles h6 {
  margin: 0px;
}
.invoice-preview .invoice-options {
  text-align: left;
}
.invoice-preview .invoice-options .form-group {
  margin: 10px 0;
}
.invoice-preview .invoice-options .form-group label {
  font-weight: normal;
}
.invoice-preview .row.notes p {
  margin: 0 15px;
  padding: 0.5em;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  font-size: 0.8em;
  color: #777;
}
.invoice-preview .row.notices {
  color: #F00;
  font-size: 0.7em;
  text-align: center;
}
.invoice-preview .row.notices h3 {
  font-size: 1.2em;
  font-weight: bold;
}
.invoice-preview .row.notices p,
.invoice-preview .row.notices h3 {
  margin: 0;
}
.invoice-preview small.notices {
  font-size: 0.7em;
}
.invoice-preview .row.summary .panel-heading,
.invoice-preview .row.signature .panel-heading {
  font-size: 1.2em;
}
.invoice-preview .invoice-preview table thead {
  display: table-header-group;
}
.invoice-preview .invoice-preview table tfoot {
  display: table-row-group;
}
.invoice-preview .invoice-preview table tr {
  page-break-inside: avoid;
}
.invoice-preview .invoice-preview .container-fluid.summary,
.invoice-preview .invoice-preview .container-fluid.signature {
  padding-top: 5mm;
}
.invoice-preview .spacer.hr {
  border-bottom: 1px solid #ddd;
  margin-bottom: 1em;
}
.invoice-preview .uppercase {
  text-transform: uppercase;
}
.invoice-preview table.invoice-details {
  width: 100%;
}
.invoice-preview table.invoice-details td:last-of-type {
  text-align: right;
}
.invoice-preview table.invoice-details .invoice-details-notice td {
  padding-top: 1em;
  font-size: 0.8em;
  text-align: center;
}
.invoice-preview table.vat-breakdown,
.invoice-preview .bank-details,
.invoice-preview table.invoice-items {
  font-size: 0.8em;
}
.invoice-preview table.invoice-totals {
  font-size: 0.9em;
}
.invoice-preview table.invoice-totals td:last-of-type {
  text-align: right;
}
.invoice-preview table.invoice-totals .text-big {
  font-size: 2em;
}
.invoice-preview .company-details {
  color: #555;
  font-size: 0.9em;
  text-align: center;
}
.invoice-preview .company-details span {
  padding: 0 0.5em;
}
.invoice-preview .table.positions-list tr {
  padding-bottom: 0;
  margin-bottom: 0;
}
.invoice-preview .table.positions-list tr > td {
  padding-bottom: 0;
  margin-bottom: 0;
}
.invoice-preview .table.positions-list tr.info-line > td {
  border: 0;
  padding: 0.13em 1em;
  background-color: #f1f1f1;
}
