
.btn-pallet-selector {
  margin: 15px 0;
  font-size: 3em;
}
.btn-plant-passport-selector {
  margin: 10px 15px;
  font-size: 1.1em;
  white-space: pre-wrap;
  text-align: left;
}
