h3.inline {
  display: inline-block;
}
.row.customer {
  padding: 0.8em 0 0.3em 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.row.list-header {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: none;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.row.list-header > div {
  margin: 0.5em 0;
}
.row.list-body {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.btn-spaced > button {
  margin: 0.1em;
}
.no-margins {
  margin: 0 !important;
}
.qualifiers {
  float: right;
}
.customer-container {
  margin-top: -45px;
}
.customer-container .alert {
  position: fixed;
  top: 55px;
  z-index: 1039;
  width: 66%;
  margin-left: 16.666%;
}
#customer-address-container,
#customer-list-container {
  position: relative;
  min-height: 120px;
}
#customer-address-container .overlay,
#customer-list-container .overlay {
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(-45deg, rgba(255, 255, 255, 0.5) 0px, rgba(255, 255, 255, 0.5) 2px, rgba(196, 224, 248, 0.3) 3px, rgba(196, 224, 248, 0.35) 4px);
  border-radius: 5px;
}
#customer-list-container .overlay {
  z-index: 98;
}
#customerForm .radio-inline {
  font-size: 90%;
}
.spinner-wrapper {
  width: 100%;
  height: 100%;
  padding: 1em;
  text-align: center;
}
.spinner {
  display: inline-block;
  width: 33px;
  height: 33px;
  border-top: 2px solid #286090;
  border-left: 1px solid #286090;
  border-radius: 33px;
  animation: spin 1s infinite linear;
  -webkit-animation: spin2 1s infinite linear;
}
.spinner.large {
  width: 66px;
  height: 66px;
  border-radius: 66px;
}
@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}
@-webkit-keyframes spin2 {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.badge-primary {
  background-color: #337ab7;
}
.badge-info {
  background-color: #5bc0de;
}
.badge-success {
  background-color: #5cb85c;
}
.badge-danger {
  background-color: #c9302c;
}
.badge-warning {
  background-color: #f0ad4e;
}
