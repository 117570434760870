#export-document-container {
  margin-top: -55px;
}
.spacer {
  height: 30px;
}
input[type="radio"].radio-btn {
  display: none;
}
.exports-page h3.panel-title.inline {
  display: inline-block;
  vertical-align: middle;
  line-height: 1.6em;
  font-size: 1.2em;
}
.exports-page .form-inline + .form-inline,
.exports-page .form-inline + .from-group {
  margin-top: 1em;
}
.exports-page .form-inline > .form-group + .form-group {
  margin-left: 1em;
}
#export_selected_form td.compact {
  padding: 2px 0;
}
#export_selected_form .table-inner {
  margin: 0;
  border: 0;
  background-color: transparent;
}
#export_selected_form .table-inner td {
  border-top: 0;
  border-bottom: 0;
  text-align: center;
}
#export_selected_form .table-inner td:nth-child(1) {
  width: 100px;
}
#export_selected_form .table-inner td:nth-child(2) {
  width: 100px;
}
#export_selected_form .table-inner td:nth-child(3) {
  width: 33px;
}
#export_selected_form .table-inner td:nth-child(4) {
  width: 100px;
}
.order-confirmation th.order-title {
  padding-right: 35px;
  vertical-align: top;
}
