@media (max-width: 1199px) {
  div.filter-bar {
    display: none;
  }
}
@media (max-width: 1199px) {
  div.orders-list {
    width: 100%;
  }
}
.pallets-count {
  cursor: pointer;
}
#stock-mode-toggle .glyphicon,
#show-stock-toggle .glyphicon {
  font-size: 22px;
  margin-left: 8px;
}
#stock-mode-toggle .glyphicon-check,
#show-stock-toggle .glyphicon-check {
  display: none;
}
#stock-mode-toggle .btn-icon,
#show-stock-toggle .btn-icon {
  width: 32px;
  height: auto;
}
#stock-mode-toggle.active,
#show-stock-toggle.active {
  background-color: #ec971f;
  border-color: #d58512;
}
#stock-mode-toggle.active .glyphicon-unchecked,
#show-stock-toggle.active .glyphicon-unchecked {
  display: none;
}
#stock-mode-toggle.active .glyphicon-check,
#show-stock-toggle.active .glyphicon-check {
  display: inline;
}
@media (max-width: 1600px) {
  #stock-mode-toggle .glyphicon,
  #show-stock-toggle .glyphicon {
    font-size: 14px;
    margin-left: 8px;
  }
  #stock-mode-toggle .btn-icon,
  #show-stock-toggle .btn-icon {
    width: 16px;
    height: auto;
  }
}
#stock-indicator .volume {
  font-size: 16px;
  font-weight: bold;
}
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
#cutter-toggle span.glyphicon {
  display: inline-block;
  margin-right: 12px;
  font-size: 24px;
}
