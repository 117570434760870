.statistics-spa {
  position: relative;
  height: 100%;
  min-height: 512px;
}
.statistics-spa h2 {
  font-size: 2.5em;
  margin-bottom: 1.25em;
}
.statistics-spa hr {
  height: 0;
  margin: 2px;
  margin-bottom: 2em;
}
.statistics-spa .color_0 {
  background-color: rgba(251, 172, 145, 0.25);
}
.statistics-spa .color_1 {
  background-color: rgba(251, 225, 182, 0.25);
}
.statistics-spa .color_2 {
  background-color: rgba(127, 223, 212, 0.25);
}
.statistics-spa .color_3 {
  background-color: rgba(243, 218, 216, 0.25);
}
.statistics-spa .color_4 {
  background-color: rgba(214, 217, 206, 0.25);
}
.statistics-spa ul.sub-group-list {
  padding: 0;
}
.statistics-spa ul.sub-group-list li {
  list-style-type: none;
  margin-bottom: 0.25em;
}
.statistics-spa .statistics-customers .statistics-customers-panel {
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 3px;
}
.statistics-spa .statistics-form {
  margin-bottom: 1em;
}
.statistics-spa .statistics-form .v-select .selected-tag {
  font-size: 0.9em;
}
.statistics-spa .statistics-form .v-select input[type="search"] {
  max-width: 10em;
}
.statistics-spa .statistics-form .v-select button.clear {
  display: none;
}
.statistics-spa .vdp-datepicker input[type="text"] {
  text-align: center;
  min-height: 36px;
  font-size: 1.1em;
}
.statistics-spa .vdp-datepicker input[type="text"].camouflaged {
  border: none;
  background-color: transparent;
  box-shadow: none;
  padding: 0;
  cursor: pointer;
  caret-color: transparent;
}
.statistics-spa .statistics-orders .panel.empty {
  opacity: 0.5;
}
.statistics-spa .statistics-orders .panel-heading {
  text-align: center;
  font-size: 1.2em;
}
.statistics-spa .panel > ul.list-group > li.list-group-item img {
  width: 16px;
  height: auto;
}
.statistics-spa .panel > ul.list-group > li.list-group-item em {
  color: #555;
  font-size: 0.75em;
}
.statistics-spa .statistics-graphs .vtc .curve.series_0 .stroke {
  stroke: #fbac91;
  stroke-width: 2;
}
.statistics-spa .statistics-graphs .vtc .curve.series_0 .fill {
  fill: #fbac91;
  opacity: 0.05;
}
.statistics-spa .statistics-graphs .vtc .curve.series_1 .stroke {
  stroke: #fbe1b6;
  stroke-width: 2;
}
.statistics-spa .statistics-graphs .vtc .curve.series_1 .fill {
  fill: #fbe1b6;
  opacity: 0.05;
}
.statistics-spa .statistics-graphs .vtc .curve.series_2 .stroke {
  stroke: #7fdfd4;
  stroke-width: 2;
}
.statistics-spa .statistics-graphs .vtc .curve.series_2 .fill {
  fill: #7fdfd4;
  opacity: 0.05;
}
.statistics-spa .statistics-graphs .vtc .curve.series_3 .stroke {
  stroke: #f3dad8;
  stroke-width: 2;
}
.statistics-spa .statistics-graphs .vtc .curve.series_3 .fill {
  fill: #f3dad8;
  opacity: 0.05;
}
.statistics-spa .statistics-graphs .vtc .curve.series_4 .stroke {
  stroke: #d6d9ce;
  stroke-width: 2;
}
.statistics-spa .statistics-graphs .vtc .curve.series_4 .fill {
  fill: #d6d9ce;
  opacity: 0.05;
}
