
.stock-entries-form[data-v-325112f0] {
  font-size: 2rem;
  padding: 1em 1em 0 1em;
}
.stock-entries-form .stock-entries-actions[data-v-325112f0],
.stock-entries-form .stock-entries-fields[data-v-325112f0] {
  margin-bottom: 1em;
}
.stock-entries-form .stock-entries-actions[data-v-325112f0] {
  justify-content: space-between;
}
.justify-end[data-v-325112f0] {
  justify-content: flex-end;
}
