.btn-toggle {
  margin: 0;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3rem;
  filter: grayscale(75%);
  color: #6b7381;
  background: #bdc1c8;
}
.btn-toggle:focus,
.btn-toggle.focus,
.btn-toggle:focus.active,
.btn-toggle.focus.active,
.btn-toggle:focus.active:hover,
.btn-toggle.focus.active:hover {
  outline: none;
  background-color: #204d74;
}
.btn-toggle:focus,
.btn-toggle.focus,
.btn-toggle:focus:hover,
.btn-toggle.focus:hover {
  background-color: #bdc1c8;
  filter: grayscale(30%);
}
.btn-toggle > .handle {
  position: absolute;
  top: 0.1875rem;
  left: 0.1875rem;
  width: 1.125rem;
  height: 1.125rem;
  background: #fff;
  border-radius: inherit;
  transition: left 0.25s;
}
.btn-toggle.active {
  transition: background-color 0.25s;
  filter: grayscale(0%);
}
.btn-toggle.active > .handle {
  left: 1.6875rem;
  transition: left 0.25s;
}
.btn-toggle.active:before {
  opacity: 0.5;
}
.btn-toggle.active:after {
  opacity: 1;
}
.btn-toggle.btn-sm:before,
.btn-toggle.btn-sm:after {
  line-height: -0.5rem;
  color: #fff;
  letter-spacing: 0.75px;
  left: 0.4125rem;
  width: 2.325rem;
}
.btn-toggle.btn-sm:before {
  text-align: right;
}
.btn-toggle.btn-sm:after {
  text-align: left;
  opacity: 0;
}
.btn-toggle.btn-sm.active:before {
  opacity: 0;
}
.btn-toggle.btn-sm.active:after {
  opacity: 1;
}
.btn-toggle.btn-xs:before,
.btn-toggle.btn-xs:after {
  display: none;
}
.btn-toggle:before,
.btn-toggle:after {
  color: #6b7381;
}
.btn-toggle.active {
  background-color: #204d74;
}
.btn-toggle.btn-lg {
  margin: 0;
  padding: 0;
  position: relative;
  border: none;
  height: 3rem;
  width: 6rem;
  filter: grayscale(75%);
}
.btn-toggle.btn-lg:focus,
.btn-toggle.btn-lg.focus,
.btn-toggle.btn-lg:focus.active,
.btn-toggle.btn-lg.focus.active,
.btn-toggle.btn-lg:focus.active:hover,
.btn-toggle.btn-lg.focus.active:hover {
  outline: none;
  background-color: #204d74;
}
.btn-toggle.btn-lg:focus,
.btn-toggle.btn-lg.focus,
.btn-toggle.btn-lg:focus:hover,
.btn-toggle.btn-lg.focus:hover {
  background-color: #bdc1c8;
  filter: grayscale(30%);
}
.btn-toggle.btn-lg > .handle {
  position: absolute;
  top: 0.375rem;
  left: 0.375rem;
  width: 2.25rem;
  height: 2.25rem;
  background: #fff;
  border-radius: inherit;
  transition: left 0.25s;
}
.btn-toggle.btn-lg.active {
  transition: background-color 0.25s;
  filter: grayscale(0%);
}
.btn-toggle.btn-lg.active > .handle {
  left: 3.375rem;
  transition: left 0.25s;
}
.btn-toggle.btn-lg.active:before {
  opacity: 0.5;
}
.btn-toggle.btn-lg.active:after {
  opacity: 1;
}
.btn-toggle.btn-lg.btn-sm:before,
.btn-toggle.btn-lg.btn-sm:after {
  line-height: 1rem;
  color: #fff;
  letter-spacing: 0.75px;
  left: 0.825rem;
  width: 4.65rem;
}
.btn-toggle.btn-lg.btn-sm:before {
  text-align: right;
}
.btn-toggle.btn-lg.btn-sm:after {
  text-align: left;
  opacity: 0;
}
.btn-toggle.btn-lg.btn-sm.active:before {
  opacity: 0;
}
.btn-toggle.btn-lg.btn-sm.active:after {
  opacity: 1;
}
.btn-toggle.btn-lg.btn-xs:before,
.btn-toggle.btn-lg.btn-xs:after {
  display: none;
}
.btn-toggle.btn-sm {
  margin: 0;
  padding: 0;
  position: relative;
  border: none;
  height: 2rem;
  width: 4rem;
  filter: grayscale(75%);
}
.btn-toggle.btn-sm:focus,
.btn-toggle.btn-sm.focus,
.btn-toggle.btn-sm:focus.active,
.btn-toggle.btn-sm.focus.active,
.btn-toggle.btn-sm:focus.active:hover,
.btn-toggle.btn-sm.focus.active:hover {
  outline: none;
  background-color: #204d74;
}
.btn-toggle.btn-sm:focus,
.btn-toggle.btn-sm.focus,
.btn-toggle.btn-sm:focus:hover,
.btn-toggle.btn-sm.focus:hover {
  background-color: #bdc1c8;
  filter: grayscale(30%);
}
.btn-toggle.btn-sm > .handle {
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  width: 1.5rem;
  height: 1.5rem;
  background: #fff;
  border-radius: inherit;
  transition: left 0.25s;
}
.btn-toggle.btn-sm.active {
  transition: background-color 0.25s;
  filter: grayscale(0%);
}
.btn-toggle.btn-sm.active > .handle {
  left: 2.25rem;
  transition: left 0.25s;
}
.btn-toggle.btn-sm.active:before {
  opacity: 0.5;
}
.btn-toggle.btn-sm.active:after {
  opacity: 1;
}
.btn-toggle.btn-sm.btn-sm:before,
.btn-toggle.btn-sm.btn-sm:after {
  line-height: 0rem;
  color: #fff;
  letter-spacing: 0.75px;
  left: 0.55rem;
  width: 3.1rem;
}
.btn-toggle.btn-sm.btn-sm:before {
  text-align: right;
}
.btn-toggle.btn-sm.btn-sm:after {
  text-align: left;
  opacity: 0;
}
.btn-toggle.btn-sm.btn-sm.active:before {
  opacity: 0;
}
.btn-toggle.btn-sm.btn-sm.active:after {
  opacity: 1;
}
.btn-toggle.btn-sm.btn-xs:before,
.btn-toggle.btn-sm.btn-xs:after {
  display: none;
}
.btn-toggle.btn-xs {
  margin: 0;
  padding: 0;
  position: relative;
  border: none;
  height: 1rem;
  width: 2rem;
  filter: grayscale(75%);
}
.btn-toggle.btn-xs:focus,
.btn-toggle.btn-xs.focus,
.btn-toggle.btn-xs:focus.active,
.btn-toggle.btn-xs.focus.active,
.btn-toggle.btn-xs:focus.active:hover,
.btn-toggle.btn-xs.focus.active:hover {
  outline: none;
  background-color: #204d74;
}
.btn-toggle.btn-xs:focus,
.btn-toggle.btn-xs.focus,
.btn-toggle.btn-xs:focus:hover,
.btn-toggle.btn-xs.focus:hover {
  background-color: #bdc1c8;
  filter: grayscale(30%);
}
.btn-toggle.btn-xs > .handle {
  position: absolute;
  top: 0.125rem;
  left: 0.125rem;
  width: 0.75rem;
  height: 0.75rem;
  background: #fff;
  border-radius: inherit;
  transition: left 0.25s;
}
.btn-toggle.btn-xs.active {
  transition: background-color 0.25s;
  filter: grayscale(0%);
}
.btn-toggle.btn-xs.active > .handle {
  left: 1.125rem;
  transition: left 0.25s;
}
.btn-toggle.btn-xs.active:before {
  opacity: 0.5;
}
.btn-toggle.btn-xs.active:after {
  opacity: 1;
}
.btn-toggle.btn-xs.btn-sm:before,
.btn-toggle.btn-xs.btn-sm:after {
  line-height: -1rem;
  color: #fff;
  letter-spacing: 0.75px;
  left: 0.275rem;
  width: 1.55rem;
}
.btn-toggle.btn-xs.btn-sm:before {
  text-align: right;
}
.btn-toggle.btn-xs.btn-sm:after {
  text-align: left;
  opacity: 0;
}
.btn-toggle.btn-xs.btn-sm.active:before {
  opacity: 0;
}
.btn-toggle.btn-xs.btn-sm.active:after {
  opacity: 1;
}
.btn-toggle.btn-xs.btn-xs:before,
.btn-toggle.btn-xs.btn-xs:after {
  display: none;
}
