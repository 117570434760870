
.stepper-container[data-v-dc233714] {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.stepper-container .btn[data-v-dc233714] {
  font-size: 1em;
}
input[type="number"][data-v-dc233714] {
  text-align: center;
  background: transparent;
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
input[type=number][data-v-dc233714]::-webkit-inner-spin-button,
input[type=number][data-v-dc233714]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
