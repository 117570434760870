#below-login-form {
  margin-top: 10px;
}
.registration-link {
  background-color: #222;
  color: white;
  max-width: 380px;
  padding: 15px;
  margin: 5em auto;
  border-radius: 6px;
}
.registration-link p {
  font-size: 12px;
}
.registration-link a[role="button"] {
  background-color: white;
  color: #222;
  font-weight: bold;
}
