@media (max-width: 1199px) {
  button.hidden-on-small,
  a.hidden-on-small {
    display: none;
  }
}
@media (max-width: 1199px) {
  button.wider-on-small,
  a.wider-on-small {
    padding: 7px 14px !important;
  }
}
@media (max-width: 1199px) {
  .list .list-row div.emphasize.more {
    margin: auto;
  }
}
@media (max-width: 1199px) {
  .list .list-row div.emphasize.big-text-on-tablets {
    margin: 10px 0;
    line-height: 12px;
    font-size: 120%;
  }
  .list .list-row div.emphasize.big-text-on-tablets.product-names {
    line-height: 1;
  }
}
@media (max-width: 1199px) {
  .list .list-row div.emphasize.big-text-on-tablets.product-names {
    margin-top: 11px;
    line-height: 12px;
    font-size: 10px;
  }
}
#order-totals {
  margin-top: 15px;
}
#totals-title {
  text-transform: uppercase;
}
@media (min-width: 1200px) {
  #palettes-total {
    padding-left: 15px;
  }
  #sq-mt-total {
    padding-left: 14px;
  }
}
@media (max-width: 1199px) {
  #palettes-total {
    padding-left: 25px;
  }
}
.extra-large {
  padding: 20px 32px !important;
}
@media (max-width: 1600px) {
  .orders-list-controls .btn-lg:not(.create-new),
  .orders-list-controls .btn-group-lg > .btn {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }
  .orders-list-controls .input-lg {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }
}
